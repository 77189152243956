.b-i-rewards-detail-container {
    @apply w-full md:max-w-[50%] px-10 pb-[60px] pt-11 md:p-[37px] xl:p-20 flex flex-col md:flex-row items-center justify-center
}

.b-i-rewards-overlay-img {
    @apply mb-11 mt-[-135px] md:mt-0 md:mb-0 md:mr-[37px] md:ml-[-25%] xl:mr-20 xl:ml-[-50%] w-full max-w-[190px] md:max-w-[200px] xl:max-w-[278px] overflow-hidden
}

.b-i-rewards-body {
    @apply w-full md:max-w-[480px] xl:max-w-[525px] flex flex-col items-center gap-6 md:gap-5 xl:gap-6
}

